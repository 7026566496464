import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

import Layout from '../../components/Layout';
import PanelDisplay from '@cof/graffiti-alley-spray-cans/molecules/PanelDisplay';
import SEO from '../../components/SEO/SEO';

import mobileAppEn from '../../assets/images/mobile-app-en.png';
import mobileAppFr from '../../assets/images/mobile-app-fr.png';
import './logout.scss';

const LogOutPage = injectIntl(({ intl }) => {
  const panelDisplayImage = intl.locale === 'en' ? mobileAppEn : mobileAppFr;
  const signInLinkStr = intl.messages['pages.online-banking-logout.link-text'];

  return (
    <Layout pageName="online-banking-logout-page">
      <div id="logout-page-wrapper">
        <section className="logout-page-content">
          <h1 id="logout-title">
            <FormattedMessage id="pages.online-banking-logout.page-header" className="logout-title" />
          </h1>
          <p id="logout-content-text">
            <FormattedMessage
              id="pages.online-banking-logout.logout-description"
              values={{
                signInLink: (
                  <Link
                    to={intl.formatMessage({ id: 'pages.online-banking-timeout.verified-link' })}
                    className="logout-log-in-link progressive logout-log-in"
                    target="_self"
                  >
                    {signInLinkStr}
                  </Link>
                ),
              }}
            />
          </p>
          <PanelDisplay mediaType="image" mediaSrc={panelDisplayImage} altTitleText="" leftPanelSize="33%">
            <section id="logout-app" className="logout-app-panel">
              <header className="logout-app-title">
                <FormattedMessage id="pages.online-banking-logout.app-content-header" />
              </header>
              <p>
                <FormattedMessage
                  id="pages.online-banking-logout.app-content-text"
                  values={{
                    trademark: (
                      <sup>
                        <FormattedMessage id="pages.online-banking-logout.trademark" />
                      </sup>
                    ),
                  }}
                />
              </p>
              <Link to="/app/" className="logout-app-button progressive button">
                <FormattedMessage id="pages.online-banking-logout.app-button-text" />
              </Link>
            </section>
            <section id="logout-help" className="logout-help-panel">
              <header className="logout-help-title">
                <FormattedMessage id="pages.online-banking-logout.help-title" />
              </header>
              <p>
                <FormattedMessage id="pages.online-banking-logout.help-content-row-1" />
              </p>
              <p>
                <FormattedMessage id="pages.online-banking-logout.help-content-row-2" />
                <br />
                <span className="logout-help-number">1-800-481-3239</span>
              </p>
              <p>
                <FormattedMessage id="pages.online-banking-logout.help-content-row-3" />
                <br />
                <span className="logout-help-number">1-804-934-2010</span>
              </p>
            </section>
          </PanelDisplay>
          <div id="logout-disclaimer" className="logout-disclaimer">
            <p id="logout-trademark-android">
              <FormattedMessage id="pages.online-banking-logout.trademark-android" />
            </p>
            <p id="logout-trademark-apple">
              <FormattedMessage id="pages.online-banking-logout.trademark-apple" />
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
});

export default LogOutPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.online-banking-logout.page-title" intl={pageContext.intl}>
    <meta name="robots" content="noindex" />
  </SEO>
);
/* eslint-enable react/prop-types */
